








































































































import Vue, { PropOptions } from 'vue';

type BannerAlertProps = Blok & {
  title: string;
  subtitle: string;
  datetime: string;
  date: string;
  time: string;
  showDateAndTime: boolean;
  showTimer: boolean;
  showTimerOnlyOnDesktop: boolean;
  imageCount: 'no-image' | 'single-image' | 'two-images' | 'big-image';
  singleImage?: any;
  smImage1?: any;
  smImage2?: any;
  bigImage?: any;
  button: any;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<BannerAlertProps>,
  },
  data() {
    return {
      isVisible: true,
      daysLeft: 0,
    };
  },
  mounted() {
    this.calculateDaysLeft();
  },
  methods: {
    closeComponent() {
      this.isVisible = false;
    },
    calculateDaysLeft() {
      const eventDate = new Date(this.blok.datetime);
      const today = new Date();
      const timeDiff = eventDate.getTime() - today.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.daysLeft = daysDiff;
    },
  },
});
